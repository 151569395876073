import React from "react";
import OdysseeCard from "./odyssee/OdysseeCard";
import { Box, Skeleton } from "@mui/material";
import InexButton from "../../InexButton";
import OdysseeThemeProvider from "../../../contexts/OdysseeThemeProvider";
import { useGetLastOdysseeQuery } from "../../../store/api/odyssee";
import { useAuthToken } from "../../../hooks/auth";

const OdysseeMainBlock = () => {
	const authToken = useAuthToken();

	const { data, isLoading } = useGetLastOdysseeQuery({ authToken });

	const odyssee = React.useMemo(() => {
		return data;
	}, [data]);

	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				flexDirection: "column",
			}}
		>
			<OdysseeThemeProvider odyssee={odyssee}>
				{!odyssee || isLoading ? (
					<Skeleton variant={"rectangle"} width={"100%"} height={700} />
				) : (
					<OdysseeCard odyssee={odyssee} />
				)}
			</OdysseeThemeProvider>
			<a href={"https://www.inexplore.com/odyssee"}>
				<InexButton
					textWithGradient={false}
					disableBackground={true}
					text={"Voir toutes nos odyssées"}
					sx={{
						marginTop: 4,
						padding: 3.5,
						width: "250px",
						backgroundColor: (theme) => theme.palette.lightgrey,
						transition: "border-color 0.3s, background 0.3s, color 0.3s,",
						"& > p": {
							color: (theme) => theme.palette.secondary.main,
							transition: "color 0.3s,",
						},
						"&:hover": {
							backgroundColor: (theme) => theme.palette.secondary.main,
							border: "none",
							"& > p": {
								color: "white",
							},
						},
					}}
				/>
			</a>
		</Box>
	);
};

export default OdysseeMainBlock;
