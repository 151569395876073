import React from "react";
import { Box, Dialog, IconButton, styled, Typography, useTheme } from "@mui/material";
import {
	CloseRounded,
	ErrorRounded,
	ImageRounded,
	KeyboardArrowLeftRounded,
	KeyboardArrowRightRounded,
} from "@mui/icons-material";
import { Swiper, SwiperSlide } from "swiper/react";

const CloseModalButton = styled(IconButton)(({ theme }) => {
	return {
		position: "absolute",
		width: "40px",
		height: "40px",
		top: "20px",
		right: "20px",
		zIndex: 100000,
	};
});

const MainContainer = styled(Box)(({ theme }) => {
	return {
		display: "flex",
		position: "relative",
		padding: theme.spacing(5),
		flexDirection: "column",
		userSelect: "none",
	};
});

const FullScreenImageViewerContext = React.createContext(null);

export const useFullScreenImageViewer = () => {
	return React.useContext(FullScreenImageViewerContext);
};

const FullScreenImageViewer = ({ open, images, onClose }) => {
	const theme = useTheme();

	const swiperRef = React.useRef(null);
	const [current, setCurrent] = React.useState(0);
	const [progress, setProgress] = React.useState(0);

	const handleGoNext = React.useCallback(() => {
		if (swiperRef.current) {
			swiperRef.current.slideNext();
		}
		setCurrent(current + 1);
	}, [swiperRef.current, current]);

	const handleGoPrevious = React.useCallback(() => {
		if (swiperRef.current) {
			swiperRef.current.slidePrev();
		}
		setCurrent(current - 1);
	}, [swiperRef.current, current]);

	const canGoNext = React.useMemo(() => {
		return current < images.length - 1;
	}, [current, images]);

	const canGoPrev = React.useMemo(() => {
		return current > 0;
	}, [current]);

	const [errorLoadingImage, setErrorLoadingImage] = React.useState(false);
	React.useEffect(() => {
		setErrorLoadingImage(false);
	}, [current]);

	// TODO : renders a Dialog with a list of images that you can scroll
	// TODO : if an image doesn't load we remove it from the list directly.
	// TODO : to check that we use the images in a list as thumbnails in the bottom
	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
			<MainContainer>
				<CloseModalButton
					onClick={(e) => {
						onClose && onClose(e);
					}}
				>
					<CloseRounded />
				</CloseModalButton>
				<Box
					sx={{
						width: "100%",
						display: "flex",
						flexDirection: "column",
						marginTop: 3,
					}}
				>
					<Box
						sx={{
							width: "100%",
							minWidth: "100%",
							minHeight: "60vh",
							position: "relative",
							display: "flex",
							alignItems: "center",
						}}
					>
						{errorLoadingImage === false ? (
							<img
								src={images[current]}
								alt={"productImage"}
								style={{
									position: "absolute",
									top: 0,
									left: 0,
									width: "100%",
									height: "100%",
									objectFit: "contain",
								}}
								onError={() => setErrorLoadingImage(true)}
								onLoad={() => setErrorLoadingImage(false)}
							/>
						) : (
							<Box
								sx={{
									minHeight: "100%",
									margin: "auto",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									flexDirection: "column",
									background: theme.palette.grey["200"],
									borderRadius: "8px",
									padding: "20px",
								}}
							>
								<ErrorRounded color={"error"} />
								<Typography mt={2}>
									{"Une erreur est survenue lors du chargement de l'image"}
								</Typography>
							</Box>
						)}
					</Box>
					{images.length > 1 && (
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								position: "relative",
								width: "100%",
								marginTop: theme.spacing(2),
							}}
						>
							<IconButton
								onClick={handleGoPrevious}
								disabled={!canGoPrev}
								sx={{
									backgroundColor: (theme) => theme.palette.background.paper,
									marginLeft: 1,
									marginRight: 1,
									color: (theme) => theme.palette.secondary.main,
									zIndex: 1000,
									"&:hover": {
										color: (theme) => theme.palette.background.paper,
										backgroundColor: (theme) => theme.palette.secondary.main,
									},
								}}
							>
								<KeyboardArrowLeftRounded
									sx={{
										fontSize: "35px",
									}}
								/>
							</IconButton>
							<Swiper
								spaceBetween={"10px"}
								slidesPerView={images.length < 3 ? images.length : 3}
								pagination={{ clickable: true }}
								scrollbar={{ draggable: true }}
								style={{
									width: "100%",
									overflow: "hidden",
								}}
								onSlideChange={(swiper) => {
									setProgress(swiper.progress);
								}}
								onSwiper={(swiper) => {
									swiperRef.current = swiper;
								}}
							>
								{images.map((image, index) => {
									const isCurrent = index === current;
									return (
										<SwiperSlide
											style={{
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
												height: "150px",
												backgroundColor: !isCurrent
													? theme.palette.grey["200"]
													: theme.palette.primary.light,
												borderRadius: "8px",
												padding: "5px",
											}}
											onClick={() => {
												setCurrent(index);
												swiperRef.current.slideTo(index);
											}}
										>
											<ImageItemInSlider
												image={image}
												key={image + index}
												isCurrent={isCurrent}
											/>
										</SwiperSlide>
									);
								})}
							</Swiper>
							<IconButton
								onClick={handleGoNext}
								disabled={!canGoNext}
								sx={{
									backgroundColor: (theme) => theme.palette.background.paper,
									marginLeft: 1,
									marginRight: 1,
									color: (theme) => theme.palette.secondary.main,
									zIndex: 1,
									"&:hover": {
										color: (theme) => theme.palette.background.paper,
										backgroundColor: (theme) => theme.palette.secondary.main,
									},
								}}
							>
								<KeyboardArrowRightRounded
									sx={{
										fontSize: "35px",
									}}
								/>
							</IconButton>
						</Box>
					)}
				</Box>
			</MainContainer>
		</Dialog>
	);
};

const FullScreenImageViewerProvider = ({ children }) => {
	const [isOpen, setOpen] = React.useState(false);
	const [imageUrls, setImageUrls] = React.useState([]);

	const openImagesInFullScreen = React.useCallback((imageUrls) => {
		if (!imageUrls) return;
		if (!Array.isArray(imageUrls)) imageUrls = [imageUrls];
		setImageUrls(imageUrls);
		setOpen(true);
	}, []);

	const ctx = React.useMemo(() => {
		return {
			isOpen,
			imageUrls,
			openImagesInFullScreen,
		};
	}, [isOpen, imageUrls, openImagesInFullScreen]);

	return (
		<FullScreenImageViewerContext.Provider value={ctx}>
			{children}
			<FullScreenImageViewer
				open={isOpen}
				onClose={() => {
					setOpen(false);
				}}
				images={imageUrls}
			/>
		</FullScreenImageViewerContext.Provider>
	);
};

export default FullScreenImageViewerProvider;

const ImageItemInSlider = ({ isCurrent, image, onClick, ...props }) => {
	const [error, setError] = React.useState(false);

	React.useEffect(() => {
		setError(false);
	}, [image]);

	if (error)
		return (
			<ImageRounded
				color={"secondary"}
				sx={{
					fontSize: 50,
				}}
			/>
		);

	return (
		<img
			src={image}
			alt={"productImage"}
			style={{
				height: "100%",
				width: "100%",
				objectFit: "contain",
			}}
			onLoad={() => {
				setError(false);
			}}
			onError={() => {
				setError(true);
			}}
		/>
	);
};
