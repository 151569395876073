import React from "react";
import { Box, Divider, Fade, Grid, Hidden, Skeleton, styled, Typography } from "@mui/material";
import odysseeImg from "../../../../resources/images/png/home/odyssee.png";
import InexCard from "../../../InexCard";
import useIsOnScreen from "../../../../hooks/useIsOnScreen";
import { useIsMobile } from "../../../../hooks/responsive";
import OdysseeFeaturePoint from "./OdysseeFeaturePoint";
import {
	CardGiftcardRounded,
	CategoryRounded,
	FolderCopyRounded,
	MovieRounded,
	ShoppingBagOutlined,
} from "@mui/icons-material";
import InexButton from "../../../InexButton";
import OdysseeGiftCard from "./OdysseeGiftCard";
import { useGetOdysseeBySlugQuery, useGetOdysseePriceQuery } from "../../../../store/api/odyssee";
import { useAuthToken } from "../../../../hooks/auth";
import { useOdysseeTheme } from "../../../../contexts/OdysseeThemeProvider";

const ImageContainer = styled(Box)(({ theme }) => {
	return {
		display: "flex",
		position: "relative",
		alignItems: "center",
		justifyContent: "center",
		overflow: "hidden",
		height: "100%",
		width: "100%",
		"& > img": {
			position: "absolute",
			top: 0,
			left: 0,
			objectFit: "cover",
			width: "100%",
			height: "100%",
		},
	};
});

const TagLineTypo = styled(Typography)(({ theme, color }) => {
	return {
		color: color,
		fontSize: "18px",
		textTransform: "uppercase",
		fontFamily: "Branding Medium",
		marginRight: theme.spacing(2),
	};
});

const OdysseePriceBlock = styled(Box)(({ theme, hasPromo = false, backgroundColor }) => {
	return {
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start",
		justifyContent: "center",
		padding: `${theme.spacing(1)} ${theme.spacing(4)}`,
		backgroundColor: backgroundColor,
		marginTop: "-15px",
	};
});

const OdysseeTitle = styled(Typography)(({ theme, color }) => {
	return {
		color: color,
		fontFamily: "Branding Bold",
		fontSize: "37px",
		lineHeight: "1.2em",
	};
});

const OdysseeDescription = styled(Typography)(({ theme, color }) => {
	return {
		color: color,
		fontFamily: "Branding Medium",
		fontSize: "16px",
		lineHeight: "1.25em",
		width: "100%",
		display: "-webkit-box",
		WebkitLineClamp: 3,
		lineClamp: 3,
		overflow: "hidden",
		"-webkit-box-orient": "vertical",
	};
});

export const CTAButton = ({ color = "secondary", hoverColor, cardTheme = undefined, ...props }) => {
	return (
		<InexButton
			{...props}
			sx={{
				padding: 3,
				backgroundColor: (theme) => (cardTheme ? cardTheme.buttons[color].background : theme.palette.lightgrey),
				transition: "border-color 0.3s, background 0.3s, color 0.3s,",
				...props.sx,
				"& > p": {
					color: (theme) => (cardTheme ? cardTheme.buttons[color].textColor : theme.palette.secondary.main),
					transition: "color 0.3s,",
					...props.sx?.["& > p"],
				},
				"&:hover": {
					backgroundColor: (theme) =>
						cardTheme ? cardTheme.buttons[color].hover.background : theme.palette.secondary.main,
					border: "none",
					...props.sx?.["&:hover"],
					"& > p": {
						color: cardTheme ? cardTheme.buttons[color].hover.textColor : "white",
						...props.sx?.["&:hover"]?.["& > p"],
					},
					"& > svg": {
						color: cardTheme ? cardTheme.buttons[color].hover.textColor : "white",
					},
				},
			}}
		/>
	);
};

const OdysseeCard = ({ odyssee, ...props }) => {
	const cardRef = React.useRef(null);

	const isVisible = useIsOnScreen(cardRef);
	const isMobile = useIsMobile();

	const contentPadding = React.useMemo(() => {
		return isMobile ? 4 : 6;
	}, [isMobile]);

	const cardTheme = useOdysseeTheme("card");

	const authToken = useAuthToken();
	const { data: dataOdysseeTime } = useGetOdysseeBySlugQuery(
		{
			authToken,
			slug: odyssee?.slug,
		},
		{
			skip: !odyssee,
		},
	);

	const [modulesNb, hours, hoursSupp] = React.useMemo(() => {
		return [
			dataOdysseeTime?.etapes.length ?? 1,
			Math.round(dataOdysseeTime?.totalRecommended / 60) ?? 1,
			Math.round(dataOdysseeTime?.totalOptional / 60) ?? 1,
		];
	}, [dataOdysseeTime]);

	const { data: dataOdysseePrice } = useGetOdysseePriceQuery(
		{
			authToken,
			id: odyssee?.idproduit,
		},
		{
			skip: !odyssee,
		},
	);

	const [lowestPrice, highestPrice] = React.useMemo(() => {
		if (!dataOdysseePrice) return [0, 0];
		const highestPrice = dataOdysseePrice.price / 100;
		const lowestPrice = highestPrice - (dataOdysseePrice.reductMag + dataOdysseePrice.reductTv) / 100;
		return [lowestPrice, highestPrice];
	}, [dataOdysseePrice]);

	// TODO : handle if the user already has the Odyssée to show tu button to continue de Odyssee.

	if (!odyssee || !cardTheme) {
		return <Skeleton variant={"rectangle"} width={"100%"} height={700} />;
	}

	// TODO : add the calculation for the items on the card
	// TODO : add the calculation of the price
	// TODO : add support for the theme of the Odyssee.

	return (
		<InexCard
			ref={cardRef}
			sx={{
				background: cardTheme.background,
				padding: 0,
				paddingTop: 0,
				paddingBottom: 0,
				boxShadow: (theme) => theme.shadows[26],
			}}
		>
			<Grid
				container
				sx={{
					display: "flex",
				}}
			>
				<Grid item xs={0} md={3.5}>
					<ImageContainer>
						<img src={`https://medias.inrees.com/img/odyssees/1_${odyssee.id}.jpg`} alt={"odysseeImg"} />
					</ImageContainer>
				</Grid>
				<Grid container item xs={12} md={8.5} pt={contentPadding}>
					<Grid
						item
						xs={12}
						sx={{
							display: "flex",
							flexDirection: "row",
							alignItem: "flex-start",
							justifyContent: "space-between",
						}}
						pl={contentPadding}
					>
						<Fade in={isVisible} timeout={500}>
							<TagLineTypo color={cardTheme.header?.slogan}>{odyssee.slogan}</TagLineTypo>
						</Fade>
						<OdysseePriceBlock hasPromo={true} backgroundColor={cardTheme.pricing?.background}>
							<Typography
								sx={{
									color: cardTheme.pricing?.textColor,
									fontSize: "26px",
									fontFamily: "Branding Bold",
								}}
							>
								{`De ${lowestPrice}€ à ${highestPrice}€`}
							</Typography>
							<Typography
								sx={{
									color: cardTheme.pricing?.textColor,
									//textDecoration: "line-through",
									fontSize: "18px",
									fontFamily: "Branding Medium",
								}}
							>
								selon votre abonnement
							</Typography>
						</OdysseePriceBlock>
					</Grid>
					<Grid item xs={12} pl={contentPadding} pr={contentPadding} mt={isMobile ? 2 : 3}>
						<Fade in={isVisible} timeout={1000}>
							<OdysseeTitle color={cardTheme.header?.title}>{odyssee.titre}</OdysseeTitle>
						</Fade>
					</Grid>
					<Grid item xs={12} mt={2} pl={contentPadding} mr={contentPadding}>
						<Fade in={isVisible} timeout={1000}>
							<OdysseeDescription
								color={cardTheme.header?.content}
								dangerouslySetInnerHTML={{ __html: odyssee.description }}
							/>
						</Fade>
					</Grid>
					<Grid item xs={12} mt={4} pl={isMobile ? 0 : contentPadding} p={isMobile ? 1 : 0}>
						<Box
							sx={{
								display: "flex",
								alignItems: "flex-start",
								justifyContent: "space-evenly",
							}}
						>
							<OdysseeFeaturePoint
								background={cardTheme.about.background}
								textColor={cardTheme.about.textColor}
								icon={<Icon1 color={cardTheme.about.iconColor} />}
								text={`${modulesNb} modules créés pour vous`}
								delay={0}
								sx={{
									marginLeft: 0.5,
									marginRight: 0.5,
								}}
							/>
							{!isMobile && (
								<Divider
									flexItem
									orientation={"vertical"}
									sx={{
										marginTop: 1,
										marginBottom: 2,
										borderRightWidth: 2,
										borderRadius: "1px",
										borderColor: "rgba(255, 255, 255, 0.12)",
									}}
								/>
							)}
							<OdysseeFeaturePoint
								background={cardTheme.about.background}
								textColor={cardTheme.about.textColor}
								icon={<Icon2 color={cardTheme.about.iconColor} />}
								text={`${hours} heure(s) exclusives en vidéo et podcast`}
								delay={200}
								sx={{
									marginLeft: 0.5,
									marginRight: 0.5,
								}}
							/>
							{!isMobile && (
								<Divider
									flexItem
									orientation={"vertical"}
									sx={{
										marginTop: 1,
										marginBottom: 2,
										borderRightWidth: 2,
										borderRadius: "1px",
										borderColor: "rgba(255, 255, 255, 0.12)",
									}}
								/>
							)}
							<OdysseeFeaturePoint
								background={cardTheme.about.background}
								textColor={cardTheme.about.textColor}
								icon={<Icon3 color={cardTheme.about.iconColor} />}
								text={`${hoursSupp} heure(s) de ressources supplémentaires`}
								delay={400}
								sx={{
									marginLeft: 0.5,
									marginRight: 0.5,
								}}
							/>
						</Box>
					</Grid>
					<Grid item xs={12} mt={2} pl={contentPadding} mr={contentPadding}>
						<a href={`/panier?id=${odyssee?.idproduit}`}>
							<CTAButton
								onClick={() => {
									window.location = "/";
								}}
								variant={"texticon"}
								icon={
									<ShoppingBagOutlined
										sx={{
											fontSize: "26px",
											color: cardTheme.buttons.primary.textColor,
										}}
									/>
								}
								textWithGradient={false}
								disableBackground={true}
								text={"Ajouter au panier"}
								color={"primary"}
								cardTheme={cardTheme}
								sx={{
									width: isMobile ? "100%" : undefined,
									padding: 3.5,
									marginRight: 2,
									flexDirection: "row-reverse",
								}}
							/>
						</a>
						<a href={`https://www.inexplore.com/odyssee/${odyssee?.slug}`}>
							<CTAButton
								textWithGradient={false}
								disableBackground={true}
								text={"En savoir plus"}
								color={"secondary"}
								cardTheme={cardTheme}
								sx={{
									width: isMobile ? "100%" : undefined,
									marginTop: isMobile ? 2 : 0,
									padding: 3.5,
								}}
							/>
						</a>
					</Grid>
					<Grid
						item
						xs={12}
						mt={8}
						container
						sx={{
							paddingTop: contentPadding / 2,
							paddingLeft: contentPadding,
							paddingBottom: contentPadding / 2,
							paddingRight: contentPadding,
							display: "flex",
							alignItems: "center",
							justifyContent: "space-between",
							width: "100%",
							background: cardTheme.background,
						}}
					>
						<Grid
							item
							xs={12}
							sm={4}
							lg={2}
							mt={3}
							mb={3}
							sx={{
								display: "flex",
								justifyContent: "center",
							}}
						>
							<OdysseeGiftCard
								image={`https://medias.inrees.com/img/odyssees/1_${odyssee.id}.jpg`}
								width={isMobile ? "65%" : "160px"}
							/>
						</Grid>
						<Grid item xs={12} sm={8} lg={5}>
							<Box
								sx={{
									display: "flex",
									alignItems: "flex-start",
									justifyContent: "center",
									flexDirection: "column",
									color: "white",
								}}
								mt={isMobile ? 5 : 0}
								ml={isMobile ? 0 : 4}
								mr={isMobile ? 0 : 2}
								mb={isMobile ? 2 : 0}
							>
								<Typography
									sx={{
										fontFamily: "Branding Bold",
										fontSize: "22px",
										marginBottom: 2,
									}}
								>
									Aussi disponible en carte cadeau !
								</Typography>
								<Typography
									sx={{
										fontFamily: "Branding Medium",
										fontSize: "16px",
									}}
								>
									Faites plaisir à un proche et offrez-lui cette odyssée.
								</Typography>
							</Box>
						</Grid>
						<Grid item xs={12} lg={4} mt={4} mb={2}>
							<a href={`/panier?id=${odyssee?.idproduit}&gift=true`}>
								<CTAButton
									variant={"texticon"}
									icon={
										<CardGiftcardRounded
											sx={{
												fontSize: "26px",
												color: cardTheme.buttons.secondary.textColor,
											}}
										/>
									}
									cardTheme={cardTheme}
									color={"secondary"}
									textWithGradient={false}
									disableBackground={true}
									text={"Offrir cette Odyssée"}
									sx={{
										width: "100%",
										minWidth: 230,
										padding: 3.5,
										flexDirection: "row-reverse",
									}}
								/>
							</a>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</InexCard>
	);
};

export default OdysseeCard;

const Icon1 = ({ color }) => {
	return (
		<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" className="">
			<path
				d="M13.3333 16.6667L20 5L26.6667 16.6667H13.3333Z"
				stroke={color}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M16.6667 29.1667C16.6667 32.3883 14.055 35 10.8333 35C7.61167 35 5 32.3883 5 29.1667C5 25.945 7.61167 23.3333 10.8333 23.3333C14.055 23.3333 16.6667 25.945 16.6667 29.1667Z"
				stroke={color}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M23.3333 23.3333H35V35H23.3333V23.3333Z"
				stroke={color}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

const Icon2 = ({ color }) => {
	return (
		<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" className="">
			<path
				d="M5 15H15.8333M35 15H15.8333M15.8333 15L24.1667 6.66669M24.1667 6.66669H29.6667C31.4112 6.66669 32.3407 6.66669 33.0368 6.96319M24.1667 6.66669H10.3333C8.46649 6.66669 7.53307 6.66669 6.82003 7.03C6.19282 7.34958 5.68289 7.85951 5.36331 8.48672C5 9.19976 5 10.1332 5 12V28C5 29.8669 5 30.8003 5.36331 31.5133C5.68289 32.1405 6.19282 32.6505 6.82003 32.97C7.53307 33.3334 8.46649 33.3334 10.3333 33.3334H29.6667C31.5335 33.3334 32.4669 33.3334 33.18 32.97C33.8072 32.6505 34.3171 32.1405 34.6367 31.5133C35 30.8003 35 29.8669 35 28V12C35 10.1332 35 9.19976 34.6367 8.48672C34.3171 7.85951 33.8072 7.34958 33.18 7.03C33.1333 7.0062 33.0856 6.98397 33.0368 6.96319M15 6.66669L6.66667 15M25 15.0003L33.0368 6.96319M25 24.1667L16.6667 29.1667V19.1667L25 24.1667Z"
				stroke={color}
				strokeWidth="2"
				strokeLinejoin="round"
			></path>
		</svg>
	);
};

const Icon3 = ({ color }) => {
	return (
		<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" className="">
			<path
				d="M6.66657 11.6667H33.3332M8.33324 6.66669H31.6666M11.4932 33.3334H28.5066C30.2118 33.3334 31.0644 33.3334 31.7404 33.014C32.3359 32.7326 32.8347 32.2812 33.1739 31.7167C33.559 31.0757 33.6438 30.2274 33.8135 28.5307L34.5601 21.0647C34.7125 19.5407 34.7887 18.7787 34.5387 18.1898C34.319 17.6724 33.9314 17.2441 33.4385 16.9741C32.8773 16.6667 32.1115 16.6667 30.58 16.6667H9.41986C7.88829 16.6667 7.1225 16.6667 6.56131 16.9741C6.06837 17.2441 5.68079 17.6724 5.46116 18.1898C5.21111 18.7787 5.28731 19.5407 5.43971 21.0647L6.18631 28.5307C6.35598 30.2274 6.44081 31.0757 6.8259 31.7167C7.16507 32.2812 7.66388 32.7326 8.25937 33.014C8.93546 33.3334 9.78803 33.3334 11.4932 33.3334ZM24.9999 25C24.9999 26.841 22.7613 28.3334 19.9999 28.3334C17.2385 28.3334 14.9999 26.841 14.9999 25C14.9999 23.1591 17.2385 21.6667 19.9999 21.6667C22.7613 21.6667 24.9999 23.1591 24.9999 25Z"
				stroke={color}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			></path>
		</svg>
	);
};
